import React from "react";
import { Heading, Box, Text, Image, Stack } from "grommet";
import { LinkButton } from "./elements/linkButton";
import styled, { keyframes } from "styled-components";
import earth from "../../icon/earth.svg"
import { StyledHeadingComponent } from "./elements/styledHeadingComponent";
import { StyledTextComponent } from "./elements/styledTextComponent";
import { textSwe } from "./elements/textSwe";
import { textEng } from "./elements/textEng";



const spin = keyframes`
0%  {-webkit-transform: rotate(0deg);}
100% {-webkit-transform: rotate(360deg);}  
}
`

const StyledBox = styled(Box)`
animation-name: ${spin};
    animation-duration: 60000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`


export const TopComponent = ({ size, language }) => {
  let headerHeight = "550px";
  let earthSize = "450px";
  let headingSize = "72px";
  let headingMarginH = "-400px";
  let headingMarginV = "2px";
  let textMarginH = "-200px";
  let textMarginV = "20px";
  let textSize = "large";

  switch (size) {
    case "xxlarge":
      headerHeight = "750px";
      earthSize = "550px";
      headingSize = "78px";
      headingMarginH = "-400px";
      headingMarginV = "2px";
      textMarginH = "-200px";
      textMarginV = "20px";
      textSize = "large";
      break
    case "xlarge":
      headerHeight = "600px";
      earthSize = "500px";
      headingSize = "66px";
      headingMarginH = "-400px";
      headingMarginV = "2px";
      textMarginH = "-200px";
      textMarginV = "20px";
      textSize = "large";
      break
    case "large":
      headerHeight = "550px";
      earthSize = "450px";
      headingSize = "52px";
      headingMarginH = "-400px";
      textMarginH = "-200px";
      textMarginV = "20px";
      textSize = "large";
      break
    case "medium":
      headerHeight = "550px";
      earthSize = "450px";
      headingSize = "46px";
      headingMarginH = "-400px";
      textMarginH = "-200px";
      textMarginV = "20px";
      textSize = "large";
      break
    case "small":
      headerHeight = "550px";
      earthSize = "450px";
      headingSize = "40px";
      headingMarginH = "0px";
      textMarginH = "0px";
      textMarginV = "20px";
      textSize = "medium";
      break
    case "xsmall":
      headerHeight = "550px";
      earthSize = "220px";
      headingSize = "28px";
      headingMarginH = "0px";
      textMarginH = "0px";
      textMarginV = "20px";
      textSize = "medium";
      break
    default:
      break
  }
  const textArray = language === "SWE" ? textSwe : textEng;

  return (

    <Box margin={{ top: "-100px" }} align="center" justify="center" height={headerHeight} style={{
      background:
        "linear-gradient( 0.15turn, #42573D, #95BD8C)",
    }}>

      <Stack>
        <StyledBox margin={{ top: "54px" }} height={earthSize} width={earthSize} >
          <Image src={earth} />
        </StyledBox>
        <Box margin={{ top: "48px" }} height={earthSize} width={earthSize} align="center" justify="center">
          <StyledHeadingComponent size={headingSize} marginH={headingMarginH} marginV={headingMarginV} text={textArray[0]} />
          <StyledHeadingComponent size={headingSize} marginH={headingMarginH} marginV={headingMarginV} text={textArray[1]} />
          {language === "ENG" ? <><StyledTextComponent size={textSize} marginH={textMarginH} color="lime" text={textArray[2]} /><StyledTextComponent size={textSize} marginH={textMarginH} color="lime" text="for a future within the planetary boundaries." /><StyledTextComponent size={textSize} marginH={textMarginH} color="#ffffff00" text=" - " /></> :
            <StyledTextComponent size={textSize} marginH={textMarginH} marginV={textMarginV} color="lime" text={textArray[2]} />}
          <Box direction="row" gap="medium">
            <LinkButton text={textArray[3]} link="#info" size={size} />
            <LinkButton text={textArray[4]} link="#contribute" size={size} />
          </Box>
        </Box>
      </Stack>
    </Box>


  )
}