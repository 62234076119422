import React, {useEffect} from "react";
import { graphql } from "gatsby";
import { Grommet } from "grommet";
import { theme } from "../theme/theme";
import { useDispatch} from "react-redux"
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { IndexPageComponent } from "../components/indexPage";

import { fetchPersons,changePersonState } from "../redux/slices/personReducer";
import { hasAnsweredCounter } from "../services/hasAnsweredCounter";


export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    persons: allSanityPerson {
      edges {
        node {
          name
          surname
          answerText
          born
          constituency
          email
          gender
          hasAnswered
          imageCaption
          imageURL
          slug{
            current
          }
          party {
            abbreviation
            image{
              ...SanityImage
              alt
            }
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = (props) => {
  const { data, errors } = props;
  const dispatch = useDispatch();
 

  useEffect((personNodes) => {
    let isMounted = true;
    if (isMounted) {
      const person = (data || {}).persons ?
      mapEdgesToNodes(data.persons) : [];
      const counter = hasAnsweredCounter(person)
      dispatch(changePersonState({key:"entities", value:person}))
      dispatch(changePersonState({key:"allEntities", value:person}))
      dispatch(changePersonState({key:"counter",value:counter}));
      dispatch(fetchPersons());
    }
    return () => {
      isMounted = false;
    };
  }, []);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const personNodes = (data || {}).persons ?
  mapEdgesToNodes(data.persons) : [];

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  
  return (
    <Grommet theme={theme}>
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <IndexPageComponent />


        {/*postNodes && (
          <BlogPostPreviewList
            title="Latest blog posts"
            nodes={postNodes}
            browseMoreHref="/archive/"
          />
        )*/}

    </Layout>
    </Grommet>
  );
};

export default IndexPage;
