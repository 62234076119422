import React from 'react';
import { Box, DropButton, Text, RadioButtonGroup } from 'grommet';
import { useDispatch, useSelector} from "react-redux"
import { changePersonState } from '../../../../redux/slices/personReducer'; 
//comment for sanity update 3

const orderByArraySwe = [
    {
        value: "hasAnswered",
        label:"Har svarat"
    },
    {
        value: "nameDESC",
        label: "Namn A->Ö",
    },
    {
        value: "nameASC",
        label: 'Namn Ö->A',
    },
    {
        value: "partyDESC",
        label: "Parti A->Ö",
    },
    {
        value: "partyASC",
        label: 'Parti Ö->A',
    },

]

const orderByArrayEng = [
    {
        value: "hasAnswered",
        label:"Has answered"
    },
    {
        value: "nameDESC",
        label: "Name A->Z",
    },
    {
        value: "nameASC",
        label: 'Name Z->A',
    },
    {
        value: "partyDESC",
        label: "Party A->Z",
    },
    {
        value: "partyASC",
        label: 'Party Z->A',
    },
]

const DropContent = ({text, language}) => {
    const dispatch = useDispatch()
    const orderByArray = language==="SWE"?orderByArraySwe:orderByArrayEng;

    return (
        <Box pad="small">
            <Box>
                <Text margin={{bottom:"6px"}}><strong>{text}</strong></Text>
                <RadioButtonGroup
                    name="verticalRadio"
                    options={orderByArray}
                    value={useSelector(state => state.person.sort)}
                    onChange={event =>  dispatch(changePersonState({key: "sort", value: event.target.value}))}
                />

            </Box>
        </Box>
    )
};


export const DropContainer = ({text, language,size}) => {

    const [open, setOpen] = React.useState();
    const onOpen = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
            <Box align="center">
                
                <DropButton
                    size={size}
                    label={text}
                    open={open}
                    onOpen={onOpen}
                    onClose={onClose}
                    dropContent={<DropContent text={text} language={language}/>}
                    dropProps={{ align: { top: 'bottom' } }}
                />
            </Box>
    );
};
