import React, {useEffect} from "react";
import { Grid, Box } from "grommet";

import { ImageCard } from "./images";

import { useDispatch,useSelector} from "react-redux"
import { fetchPersons } from "../../../../redux/slices/personReducer";


export const ResponsiveGrid = ({size, language}) => {
    const persons = useSelector((state) => state.person.entities);
    const sort = useSelector((state) => state.person.sort);
    const dispatch = useDispatch();

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
          dispatch(fetchPersons());
        }
        return () => {
          isMounted = false;
        };
      }, [sort]);
      let columns = [];
    if(size === "small"||size==="xsmall"){
      columns = ["small"];
    }else if(size==="medium"){
      columns = ["small","small","small","small"]
    }else{
      columns =["small", "small", "small", "small", "small"];
    } 

    return (
        <Box>
        <Grid columns={columns}>
            {persons && persons.map(person =>
            <Box  margin="small" elevation="xsmall" round="xsmall">
                <ImageCard size={size} person={person} language={language}/>
            </Box>)}
        </Grid>
        </Box>
    )
}