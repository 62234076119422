import React from "react";
import { Stack, Box, Text, Image } from "grommet";
import { Link } from "gatsby";
import { buildImageObj } from "../../../../lib/helpers";
import { imageUrlFor } from "../../../../lib/image-url";
import styled from "styled-components"
const InternalLink = styled(Link)`
text-decoration: none;
:link{
    color: inherit;
}
:visited {
  color: inherit;
}
`
export const ImageCard = ({ person, language }) => {

    return (
        <InternalLink
            to={person.slug.current}
        >
            <Stack>
                <Image fill src={person.imageURL} alt={person.imageCaption} />

                <Box direction="row" justify="between" fill>
                    <Box />
                    <Box
                        height="55px"
                        width="55px"
                        background="#ffffff"
                        round="large"
                        align="center"
                        pad="11px"
                        margin="-10px"
                    >

                        <Image
                            fit="cover"
                            src={imageUrlFor(buildImageObj(person.party.image))
                                .url()}
                            alt={person.party.image.alt}
                        />

                    </Box>
                </Box>
                {person.hasAnswered?
                <Box pad={{bottom:"4px"}} direction="column" justify="end" align="center" fill>
                    <Box height="24px" width="small" background="lightGreen" align="center">
                        <Text color="white">{language==="SWE"?"Har svarat!":"Has answered!"}</Text>
                    </Box>
                </Box>
                :
                <Box pad={{bottom:"4px"}} direction="column" justify="end" align="center" fill>
                    <Box height="24px" width="small" background="#DE1A1A" align="center">
                        <Text color="white">{language==="SWE"?"Har inte svarat!":"Has not answered!"}</Text>
                    </Box>
                </Box>}

            </Stack>
            <Box align="center">
                <Text color="darkGreen" textAlign="center" margin="xsmall">{person.name}</Text>
            </Box>
        </InternalLink>
    )
}