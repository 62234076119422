import React from "react";
import {  Box } from "grommet";

import "./style.css";

import { LargeComponent } from "./largeComponent";
import { SmallComponent } from "./smallComponent";

export const InfoComponent = ({size, language}) => {


    return (
        <Box id="info">
            {size==="xsmall"||size==="small"?<SmallComponent language={language}/>:<LargeComponent language={language}/>}
        </Box>
    )
}