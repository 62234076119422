import React from "react";
import { Heading } from "grommet";
import styled from "styled-components";

const StyledHeading = styled(Heading)`
margin-left: ${props => props.marginH};
margin-right: ${props => props.marginH};
margin-top: ${props => props.marginV};
margin-bottom: ${props => props.marginV};
`

export const StyledHeadingComponent = (props) => {
    return(
        <StyledHeading textAlign="center" marginH={props.marginH} marginV={props.marginV} size={props.size} level="1" weight="bold" color="lime">
            {props.text}
            </StyledHeading>
    )
}