import React,{useState} from "react";
import { Text, Box, Heading, Button } from "grommet";
import MailPopup from "../infoComponent/mailPopup";
import { Card } from "./card"
import { useSelector} from "react-redux"
import { textSwe } from "./textSwe";
import { textEng } from "./textEng";

export const ContributeComponent = ({size, language}) => {
    const [showModal, setShowModal] = useState(false)
    const counter = useSelector(state => state.person.counter)
    let dir = size==="small" || size ==="xsmall" ? "column":"row";
    let textSize = size==="small" || size ==="xsmall" ? "16px":"xlarge";
    const textArray = language==="SWE"?textSwe:textEng;
    const share = language ==="SWE"?"Sprid vidare!": "Tell your friends!"
    return (
        <Box id="contribute" background="#F7F7F7" >
<Box width="90%" alignSelf="center" align="center" pad="medium" gap="small">
                <Heading  color="darkGreen" level="2" weight="bold" >{textArray[0]}</Heading>
                    <Box direction={dir} justify="start" gap="large">
                        <Card
                            text={textArray[1]}
                            number="1"
                        />
                        <Card
                            text={textArray[2]}
                            number="2"
                        />
                        <Card
                            text={textArray[3]}
                            number={share}
                        />
                        {/*<Image src={tree} opacity="medium"/>*/}
                    </Box>
                    
                    <Text color="darkGreen" size="medium">{textArray[4]} <Button onClick={()=>setShowModal(true)}><Text weight="bold" color="darkGreen" size="medium">{textArray[5]}</Text></Button></Text>
                    { showModal && <MailPopup language={language} setClose={setShowModal} />}
                    
                        <Box background="indexGreen"  pad="medium">
                            <Text size={textSize} color="lime" weight="bold">{counter}{textArray[6]}</Text>
                        </Box>
                    </Box>

        </Box>
    )
}