export const textSwe=[
    "Vad är det här?",
    "Vi har en klimatlag. Vi har nationella målsättningar. Men en enig forskarkår säger ändå: det räcker inte, vi har inte tid. Vi måste gå mycket snabbare framåt. Vi har alla ett ansvar, men störst makt, och därmed störst ansvar, har riksdagen. Riksdagen behöver ge vår regering i uppdrag att fatta beslut om tio nödvändiga punkter.",
    "Det här är ett privat initiativ",
    " för att uppmana våra riksdagsledamöter att ta det här beslutet.",
    " Du kan vara med och delta!", 
    " Det gör du enkelt genom att skicka",
    " e-post till riksdagsledamöterna.",
    " Sprid gärna initiativet vidare till vänner och bekanta så fler få kan uppmana riksdagsledamöterna att agera.",
    "Det färdigskrivna e-postmeddelandet kan du läsa ",
    "här.",
    "Professor Johan Rockström inledde 2020-talet med att 1 januari 2020 tala i Sveriges radio P1’s ",
    "vinterprat",
    ". Där lyfter han fram tio saker som vi måste göra – så fort som möjligt. Dessa tio punkter kan du se här under.",
]