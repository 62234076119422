import React from "react";
import {Box, Text} from "grommet";

export const Card = ({text,number}) => {
    return (
        <Box pad="small"  width="220px" height="320px" background="indexGreen" align="center">
                <Box height="30%" justify="center" align="center"> 
                {number === "1" || number === "2"?<Box align="center" justify="center" height="58px" width="58px" border={{color:"darkGreen",size:"small"}} round="full">
                        <Text size="xlarge" weight="bold" color="darkGreen">{number}</Text>
                    </Box>:<Text size="xlarge" textAlign="center" weight="bold" color="darkGreen">{number}</Text>}
                    </Box>
            <Box width="90%">
            <Text textAlign="center"   size="medium" color="darkGreen">{text}</Text>
            </Box>
        </Box>
    )
}