import React,{useState} from "react";
import { Text, Box, Heading, Stack, Image, Anchor,Grid,Button } from "grommet";
//import Carousel from 'react-elastic-carousel'
import MailPopup from "./mailPopup";
import { Card } from "./card";
import "./style.css";
import { cardText } from "./cardText";
import { cardTextEng } from "./cardTextEng";
import riksdagen2 from "../../icon/riksdagen2.jpg"
import { GiRoad, GiFactory, GiMineWagon, GiWheat, GiRiver, GiMoneyStack, GiEuropeanFlag } from 'react-icons/gi';
import { IoSync } from 'react-icons/io5'
import { GoLaw } from 'react-icons/go'
import { FaHandshake } from 'react-icons/fa'
import { textSwe } from "./textSwe";
import { textEng } from "./textEng";
import styled from "styled-components"
import { Link } from "gatsby";
const InternalLink = styled(Link)`
text-decoration: none;
:link {
  color: inherit;
}
:visited {
  color: inherit;
}
`

export const LargeComponent = ({language}) => {
    const [showModal, setShowModal] = useState(false)
    const cardTextArray = language==="SWE"?cardText:cardTextEng;
    const textArray = language==="SWE"?textSwe:textEng;
    const talkLink = language==="SWE"?"https://sverigesradio.se/avsnitt/1417244":"https://sverigesradio.se/avsnitt/1425542";
    const columns =["1fr","1fr","1fr","1fr","1fr"];
    return(
        <Stack>
                <Box  height="1060px">
                    <Image fill opacity="medium" src={riksdagen2} />
                </Box>
                <Box pad="medium" align="center">
                    <Heading size="large" level="2" color="darkGreen" weight="bold">{textArray[0]}</Heading>
                    <Box background="indexGreen" pad="large" round="small" width="xlarge" gap="small">
                        <Text color="darkGreen" size="medium">{textArray[1]}</Text>
                        <Text color="darkGreen" size="medium" >
                            <Anchor href="/contact">{textArray[2]}</Anchor>
                            {textArray[3]}
                        </Text>
                        <Text>
                        <Text weight="bold" color="darkGreen" size="medium">
                        {textArray[4]}
                        </Text>
                        <Text color="darkGreen" size="medium">
                        {textArray[5]}
                        </Text>
                        <InternalLink to="#contribute"> <Text weight="bold" color="darkGreen" size="medium">{textArray[6]}</Text></InternalLink>
                        <Text color="darkGreen" size="medium">{textArray[7]}</Text>
                        </Text>
                        <Text color="darkGreen" size="medium">{textArray[8]} <Button onClick={()=>setShowModal(true)}><Text weight="bold" color="darkGreen" size="medium">{textArray[9]}</Text></Button></Text>
                        <Text color="darkGreen" size="medium">
                        {textArray[10]}
                        <Anchor href={talkLink}>{textArray[11]}</Anchor>
                        <Text color="darkGreen" size="medium">{textArray[12]}</Text>
                        </Text>
                    </Box>
                    {/*
                    <Box margin={{ top: "32px" }} width="xlarge" alignSelf="center">
                        <Carousel itemsToShow={3} pagination={false} >
                            <Card text={cardTextArray[0]} number="1" logo={<GiRoad color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[1]} number="2" logo={<GiFactory color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[2]} number="3" logo={<GiMineWagon color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[3]} number="4" logo={<GiEuropeanFlag color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[4]} number="5" logo={<GiWheat color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[5]} number="6" logo={<GiRiver color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[6]} number="7" logo={<GiMoneyStack color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[7]} number="8" logo={<IoSync color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[8]} number="9" logo={<GoLaw color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[9]} number="10" logo={<FaHandshake color="#D0FF32" size="48px" />} />
                        </Carousel>

                    </Box>*/}
                    
                     <Box align="center" width="xlarge"  margin={{ top: "32px" }}>
                        <Grid align="center" gap="small" columns={columns}>
                        <Card text={cardTextArray[0]} number="1" logo={<GiRoad color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[1]} number="2" logo={<GiFactory color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[2]} number="3" logo={<GiMineWagon color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[3]} number="4" logo={<GiEuropeanFlag color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[4]} number="5" logo={<GiWheat color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[5]} number="6" logo={<GiRiver color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[6]} number="7" logo={<GiMoneyStack color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[7]} number="8" logo={<IoSync color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[8]} number="9" logo={<GoLaw color="#D0FF32" size="48px" />} />
                            <Card text={cardTextArray[9]} number="10" logo={<FaHandshake color="#D0FF32" size="48px" />} />
                        </Grid>
                    </Box>
                    
                </Box>
                { showModal && <MailPopup language={language} setClose={setShowModal} />}
            </Stack>
    )
}