import React from "react"
import { MailComponentEng } from "../../personPage/elements/mailComponentEng"
import { MailComponentSwe } from "../../personPage/elements/mailComponentSwe"
import { Layer,Box,Button,Text} from "grommet"


const MailPopup = ({language,setClose}) => (
                <Layer modal animation="fadeIn" onClickOutside={()=>setClose(false)} responsive={ false } >
                    <Box alignSelf="center" height="medium" overflow={{vertical:"scroll"}} width="large" style={{maxWidth:"95vw"}}  gap="small" pad="medium" justify="evenly">
                        <Box direction="column" align="end">
                            <Box round="xsmall" width="24px" height="200px"  background="lightGreen">
                                <Button fill onClick={()=>setClose(false)}>
                                    <Text margin={{left:"5.5px"}}>X</Text>
                                </Button>
                            </Box>
                        </Box>
                        {language==="SWE"?<MailComponentSwe/>:<MailComponentEng/>}
                    </Box>
                </Layer> 
                )        
        


export default MailPopup
