import React,{useState} from "react";
import { Text, Box, Heading, Anchor,Button } from "grommet";
import MailPopup from "./mailPopup";
import { SmallCard } from "./smallCard";
import "./style.css";
import { cardText } from "./cardText";
import { cardTextEng } from "./cardTextEng";
import { GiRoad, GiFactory, GiMineWagon, GiWheat, GiRiver, GiMoneyStack, GiEuropeanFlag } from 'react-icons/gi';
import { IoSync } from 'react-icons/io5'
import { GoLaw } from 'react-icons/go'
import { FaHandshake } from 'react-icons/fa'
import { textSwe } from "./textSwe";
import { textEng } from "./textEng";
import styled from "styled-components"
import { Link } from "gatsby";
const InternalLink = styled(Link)`
text-decoration: none;
:link {
  color: inherit;
}
:visited {
  color: inherit;
}
`

export const SmallComponent = ({language}) => {
    const [showModal, setShowModal] = useState(false)
    const cardTextArray = language==="SWE"?cardText:cardTextEng;
    const textArray = language==="SWE"?textSwe:textEng;
    const talkLink = language==="SWE"?"https://sverigesradio.se/avsnitt/1417244":"https://sverigesradio.se/avsnitt/1425542";

    return(
        <Box pad="small" align="center">
                    <Heading size="medium" level="2" color="darkGreen" weight="bold">{textArray[0]}</Heading>
                    <Box background="indexGreen" pad="small" round="small" gap="small">
                        <Text color="darkGreen" size="small">
                            {textArray[1]}  
                        </Text>
                        <Text color="darkGreen" size="small" >
                            <Anchor href="/contact">{textArray[2]}</Anchor>{textArray[3]}
                        </Text>
                        <Text>
                        <Text color="darkGreen" size="small">
                        {textArray[4]}
                        </Text>
                        <Text color="darkGreen" size="small">
                        {textArray[5]}
                        </Text>
                        <InternalLink to="#contribute"> <Text weight="bold" color="darkGreen" size="small">{textArray[6]}</Text></InternalLink>
                        <Text color="darkGreen" size="small">{textArray[7]}</Text>
                        </Text>
                        <Text color="darkGreen" size="small">{textArray[8]} <Button onClick={()=>setShowModal(true)}><Text weight="bold" color="darkGreen" size="small">{textArray[9]}</Text></Button></Text>
                        
                        <Text color="darkGreen" size="small">
                        {textArray[10]}
                        <Anchor href={talkLink}>{textArray[11]}</Anchor>
                        <Text color="darkGreen" size="small">{textArray[12]}</Text>
                        </Text>
                    </Box>
                    <Box margin={{ top: "6px" }} alignSelf="center" gap="small">
                            <SmallCard text={cardTextArray[0]} number="1" logo={<GiRoad color="#D0FF32" size="48px" />} />
                            <SmallCard text={cardTextArray[1]} number="2" logo={<GiFactory color="#D0FF32" size="48px" />} />
                            <SmallCard text={cardTextArray[2]} number="3" logo={<GiMineWagon color="#D0FF32" size="48px" />} />
                            <SmallCard text={cardTextArray[3]} number="4" logo={<GiEuropeanFlag color="#D0FF32" size="48px" />} />
                            <SmallCard text={cardTextArray[4]} number="5" logo={<GiWheat color="#D0FF32" size="48px" />} />
                            <SmallCard text={cardTextArray[5]} number="6" logo={<GiRiver color="#D0FF32" size="48px" />} />
                            <SmallCard text={cardTextArray[6]} number="7" logo={<GiMoneyStack color="#D0FF32" size="48px" />} />
                            <SmallCard text={cardTextArray[7]} number="8" logo={<IoSync color="#D0FF32" size="48px" />} />
                            <SmallCard text={cardTextArray[8]} number="9" logo={<GoLaw color="#D0FF32" size="48px" />} />
                            <SmallCard text={cardTextArray[9]} number="10" logo={<FaHandshake color="#D0FF32" size="48px" />} />

                    </Box>
                    { showModal && <MailPopup language={language} setClose={setShowModal} />}
                </Box>
    )
}