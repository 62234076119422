import React from "react";
import {Box, Text} from "grommet";

export const SmallCard = ({text,number,logo}) => {
    const circle = () => {
        return (
<Box align="center" justify="center" height="30px" width="30px" border={{color:"white",size:"small"}} round="full">
                        <Text color="white">{number}</Text>
                    </Box>
        )
    }
    return (
        <Box direction="row" elevation="medium" round="small" pad="small" gap="small" background="indexGreen" align="center">
            {logo ? logo: circle}
            <Box width="90%">
            <Text weight="bold" textAlign="center" wordBreak="break-word" size="small" color="darkGreen">{text}</Text>
            </Box>
        </Box>
    )
}