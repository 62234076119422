export const textEng=[
    "What is this?",
    "We have a Climate Act. We have national climate goals. Nevertheless climate scientists continue to tell us: it’s not enough, we don’t have time. We must act now. We all have a responsibility, but the greatest power, and therefore the greatest responsibility, lies with the national parliament - Sweden’s Riksdag. Sweden’s Riksdag needs to commission our government to decide on ten essential action points for a sustainable future. ",
    "This is a citizen initiative",
    " to urge our elected Members of Parliament to make this decision.", 
    "You can participate! ",
    "You do this easily by sending an",
    " email to the Members of Parliament.",
    " Feel free to spread the initiative to friends and acquaintances so that more people can urge Members of Parliament to act.",
    "You can read the email by clicking ",
    "here.",
    "On January 1, 2020, ",
    "Professor Dr. Johan Rockström held a speech on Swedish Radio",
    ", where he highlighted ten things we must do for a future within the planetary boundaries - and we must do them now. You can see these ten action points here below.",
]