import React,{useContext} from "react";
import {Box, ResponsiveContext} from "grommet"

import { TopComponent } from "./topComponent/index";
import { InfoComponent } from "./infoComponent/index";
import { ContributeComponent } from "./contributeComponent/index";
import { BottomComponent } from "./bottomComponent/index";
import {useSelector} from "react-redux"

export const IndexPageComponent = () => {
    const size = useContext(ResponsiveContext)
    const language = useSelector(state=> state.language.language);
    return (
        <Box>
            <TopComponent size={size} language={language}/>
            <InfoComponent size={size} language={language}/>
            <ContributeComponent size={size} language={language}/>
            <BottomComponent size={size} language={language}/>
        </Box>
    )
}