import React from "react";
import { Text } from "grommet";
import styled from "styled-components";

const StyledText = styled(Text)`
margin-left: ${props => props.marginH};
margin-right: ${props => props.marginH};
margin-top: ${props => props.marginV};
margin-bottom: ${props => props.marginV};
`

export const StyledTextComponent = (props) => {
    return(
        <StyledText textAlign="center" marginH={props.marginH} marginV={props.marginV} size={props.size} weight="bold" color={props.color}>
            {props.text}
            </StyledText>
    )
}