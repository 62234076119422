import React from "react";
import { Box, Text } from "grommet";
import { IoIosArrowDropdown } from "react-icons/io";
import styled from "styled-components"
import { Link } from "gatsby";

const InternalLink = styled(Link)`
text-decoration: none;
:link {
  color: inherit;
}
:visited {
  color: inherit;
}
`
export const LinkButton = ({ text,link,size }) => {
  const textSize = size === "xsmall"?"xsmall":"medium";

  return (
    <InternalLink to={link}>
    <Box direction="row" pad="small" align="center" gap="xsmall" border={{color:"darkGreen", size:"4px"}}>
      
      <IoIosArrowDropdown color="#D0FF32" />
      <Text alignSelf="center" weight="bold" size={textSize} textAlign="center" color="lime">{text}</Text>
      
    </Box>
    </InternalLink>
  )
}