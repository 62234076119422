import React,{useContext} from "react";
import {Box, Text,ResponsiveContext} from "grommet";

export const Card = ({text,number,logo}) => {
    const size = useContext(ResponsiveContext)
    const textSize = size !=="medium" ? "14px" : "12px";

    const circle = () => {
        return (
<Box align="center" justify="center" height="30px" width="30px" border={{color:"white",size:"small"}} round="full">
                        <Text color="white">{number}</Text>
                    </Box>
        )
    }
    return (
        <Box elevation="medium" round="small" pad={{horizontal:"xsmall",vertical:"medium"}} gap="small"  style={{minWidth:"140px"}} height="small" background="indexGreen" align="center">
            {logo ? logo: circle}
            <Box width="90%">
            <Text weight="bold" textAlign="center" wordBreak="break-word" size={textSize} color="darkGreen">{text}</Text>
            </Box>
        </Box>
    )
}