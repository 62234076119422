import React from "react";
import { Box, TextInput } from "grommet";
import { ResponsiveGrid } from "./elements/responsiveGrid";
import { DropContainer } from "./elements/dropContainer";
import { useDispatch } from "react-redux"
import { filterPersons } from "../../../redux/slices/personReducer";
import { textSwe } from "./elements/textSwe";
import { textEng } from "./elements/textEng";


export const BottomComponent = ({ size,language }) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState('');
  const valueChange = (event) => {
    setValue(event.target.value)
    dispatch(filterPersons(event.target.value))
  }
  const textArray = language==="SWE"?textSwe:textEng;
  const sizeDropContainer = size === "xsmall"? "small":"medium";
  const dir = size === "xsmall"? "column":"row";
  const gapSize = size === "xsmall"? "small":"large";
  return (
    <Box gap="small" pad="medium" align="center" background="#F7F7F7">
      <Box direction={dir} gap={gapSize}>
        <Box background="white" width="medium" >
          <TextInput
            size={sizeDropContainer}
            placeholder={textArray[0]}
            textAlign="center"
            value={value}
            onChange={event => valueChange(event)}
            backgroundColor="#ffffff"
          />
        </Box>
        <DropContainer text={textArray[1]} language={language} size={sizeDropContainer}/>
      </Box>
        <ResponsiveGrid size={size} language={language}/>
    </Box>
  )
}